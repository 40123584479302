import React from 'react';
import styled from 'styled-components';
import { Variables } from '../../styles/Variables';

const Svg = styled.svg`
    polyline {
        stroke-width: 8;
        stroke: ${Variables.white};
    }
`;

export default function Hamburger() {
    return (
        <Svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 32 32'
            width='100%'
            height='100%'
        >
            <path
                stroke={`${Variables.white}`}
                d='M16 4.783c-3.777 0-7.554.403-11.66 1.207a1 1 0 0 0-.781.752 5.46 5.46 0 0 0 0 2.516 1 1 0 0 0 .78.752c8.214 1.609 15.108 1.609 23.321 0a1 1 0 0 0 .78-.752 5.46 5.46 0 0 0 0-2.516 1 1 0 0 0-.78-.752C23.554 5.186 19.777 4.783 16 4.783zm0 1.994c3.39 0 6.805.375 10.494 1.057.006.111.006.22 0 .332-7.377 1.364-13.61 1.364-20.988 0a3.41 3.41 0 0 1 0-.332C9.194 7.152 12.61 6.777 16 6.777zm0 6.006c-3.777 0-7.554.403-11.66 1.207a1 1 0 0 0-.781.752 5.46 5.46 0 0 0 0 2.516 1 1 0 0 0 .78.752c8.214 1.609 15.108 1.609 23.321 0a1 1 0 0 0 .78-.752 5.46 5.46 0 0 0 0-2.516 1 1 0 0 0-.78-.752c-4.106-.804-7.883-1.207-11.66-1.207zm0 1.994c3.39 0 6.805.375 10.494 1.057.006.111.006.22 0 .332-7.377 1.364-13.61 1.364-20.988 0a3.411 3.411 0 0 1 0-.332c3.688-.682 7.104-1.057 10.494-1.057zm0 6.006c-3.777 0-7.554.403-11.66 1.207a1 1 0 0 0-.781.752 5.46 5.46 0 0 0 0 2.516 1 1 0 0 0 .78.752c8.214 1.609 15.108 1.609 23.321 0a1 1 0 0 0 .78-.752 5.46 5.46 0 0 0 0-2.516 1 1 0 0 0-.78-.752c-4.106-.804-7.883-1.207-11.66-1.207zm0 1.994c3.39 0 6.805.375 10.494 1.057.006.111.006.22 0 .332-7.377 1.364-13.61 1.364-20.988 0a3.411 3.411 0 0 1 0-.332c3.688-.682 7.104-1.057 10.494-1.057z'
            />
        </Svg>
    );
}
